<template>
  <div
    class="wrap-practice h-100 overflow-auto position-relative"
    :class="{'overflow-hidden': isLoading}"
  >
    <base-header :is-title-bold="$isJungo" :title="$t('下棋')"></base-header>
    <div
      class="practice d-flex flex-column justify-content-center align-items-center scroll-bar"
    >
      <b-row class="p-5" no-gutters>
        <b-col cols="12" class="p-0 position-relative" @click="go2Game">
          <b-img
            fluid
            class="mb-3 game"
            :srcset="require('@/assets/img/practice/btn-game.png?srcset')"
            alt="game"
          ></b-img>
          <span
            v-if="!$isJungo"
            class="title-ai-game position-absolute text-white font-weight-bold"
            >{{ $t('AI對弈') }}</span
          >
        </b-col>
        <b-col cols="12" class="p-0 position-relative" @click="go2Pvp">
          <b-img
            fluid
            class="pvp"
            :srcset="require('@/assets/img/practice/btn-tsume.png?srcset')"
            alt="pvp"
          ></b-img>
          <span
            v-if="!$isJungo"
            class="title-pvp position-absolute text-white font-weight-bold"
            >{{ $t('對戰') }}</span
          >
        </b-col>
      </b-row>
    </div>
    <modal-message-box
      v-if="isModalMessageBoxShow"
      :result-content="$t('是否繼續上次的對局？')"
      :buttons="messageBoxButtons"
      :has-btn-close="true"
      @on-item-click="onModalMessageBoxClick"
      @close="closeMessageBox"
    >
    </modal-message-box>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import ModalMessageBox from '@/components/Modal/ModalMessageBox.vue';
import BackEvent from '@/lib/base/backEvent.js';

export default {
  name: 'Practice',
  components: {
    BaseHeader,
    ModalMessageBox,
  },
  data() {
    return {
      isModalMessageBoxShow: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    hasUnfinishedGame() {
      return this.$store.getters['aiGame/hasUnfinishedGame'];
    },
    messageBoxButtons() {
      return [
        {
          variant: this.$isJungo ? 'primary' : 'secondary',
          value: 'restart',
          content: '不繼續',
        },
        {
          variant: this.$isJungo ? 'secondary' : 'primary',
          value: 'continue',
          content: '繼續',
        },
      ];
    },
  },
  methods: {
    async go2Game() {
      this.$store.commit('env/setIsLoading', true);
      await this.getUnfinishedGame();
      if (this.hasUnfinishedGame) {
        BackEvent.addEvent(() => {
          this.closeMessageBox();
        });
        this.isModalMessageBoxShow = true;
      } else {
        this.$device.isMobile
          ? this.$router.push('/practice/gamelist')
          : this.$router.push({name: 'practiceGame'});
      }
    },
    async getUnfinishedGame() {
      await this.$store.dispatch('aiGame/getUnfinishedGame');
      this.$store.commit('env/setIsLoading', false);
    },
    go2Pvp() {
      if (!this.isLoading) {
        this.$router.push({name: this.$isJungo ? 'pvp-menu' : 'pvp-home'});
      }
    },
    async onModalMessageBoxClick(value) {
      BackEvent.popEvent();
      this.isModalMessageBoxShow = false;
      if (value === 'continue') {
        this.$router.push('/practice/game');
      } else if (value === 'restart') {
        this.$store.commit('env/setIsLoading', true);
        await this.$store.dispatch('aiGame/resign');
        this.$store.commit('aiGame/resetGameData');
        this.$store.commit('env/setIsLoading', false);
        this.$device.isMobile
          ? this.$router.push('/practice/gamelist')
          : this.$router.push({name: 'practiceGame'});
      }
    },
    closeMessageBox() {
      BackEvent.popEvent();
      this.isModalMessageBoxShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.practice {
  overflow: overlay;
  height: calc(100% - 56px);
  .title-ai-game {
    bottom: 20%;
    right: 10%;
    font-size: 9vmin;
  }
  .title-pvp {
    top: 14%;
    left: 20%;
    font-size: 9vmin;
  }
}
</style>
